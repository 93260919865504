.scroll-to-top {
  position: fixed;
  bottom: -5px;
  right: 16px;
  background: linear-gradient(45deg, #333, #6b6b6b);
  color: #fff;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px 8px 0px 0px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.scroll-to-top.visible {
  opacity: 1;
}
