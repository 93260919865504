.career-container {
  margin: 6rem 0;
}

.career-text-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  p {
    margin-bottom: 2rem;
  }
}

.title {
  font-family: "DM Serif Display", serif;
  font-size: 2.5rem;
}

p {
  font-size: 0.9rem;
  color: #656565;
  margin: 0rem;
  line-height: 2;
}

hr {
  margin: 1.5rem 0;
  width: 30%;
}

.skills-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.skills {
  border-radius: 30px;
  border: 0.5px solid #333;
  padding: 10px 19px;
  margin: 0.5rem 1rem;
  text-align: center;
}

.button1-styles {
  display: inline-block;
  width: 270px;
  padding: 10px 24px;
  border: 0.5px solid #e2e2e2;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  font-size: 0.9rem;
  color: #fff;
  margin: 0 auto;
  z-index: 1;
  border-radius: 32px;
  background: linear-gradient(45deg, #333, #6b6b6b);
}

/* Remove .button1-styles:before and .button1-styles:after rules */

.down__button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}

.down__button,
.down__button__icon,
.down__button__text {
  transition: all 0.3s;
}

.down__button .down__button__text {
  color: #fff;
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(0);
}

.down__button .down__button__icon {
  position: absolute;
  transform: translateX(0px);
  right: 8px;
  height: 100%;
  width: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.down__button .svg {
  width: 20px;
  fill: #fff;
}

.down__button:hover {
  background: linear-gradient(45deg, #333, #6b6b6b);
}

.down__button:hover .down__button__text {
  color: transparent;
}

.down__button:hover .down__button__icon {
  width: 100%;
  transform: translateX(0);
  right: 0;
}

.down__button:active .down__button__icon {
  background-color: #39bda7;
}

@media screen and (max-width: 1199px) {
  .skills-container {
    width: 100%;
  }
  .skills {
    padding: 10px 12px;
    // margin: 0.5rem 0.8rem;
  }
}

@media screen and (max-width: 991px) {
  .career-container {
    margin: 3.5rem 0;
  }
  .skills {
    padding: 5px 19px;
    margin: 0.5rem 0.8rem;
  }
  code {
    font-size: 0.82rem;
  }
  .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .career-text-container {
    width: 97%;
  }
  .skills-col {
    order: 1;
    margin-top: 4rem;
  }
  .skills {
    padding: 5px 25px;
    margin: 0.5rem 0.5rem;
  }
}
