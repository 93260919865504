footer {
  background: linear-gradient(-45deg, #333, #6b6b6b);
  // text-align: center;
  .copyright {
    padding: 2rem 0rem;
    color: #fff;
    a {
      color: #39bda7;
      text-decoration: none;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #39bda7;
        transition: all 0.3s ease;
      }

      &:hover::before {
        width: 100%;
      }
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    height: 25px;
    filter: invert(1);
    transition: all 0.3s ease, transform 0.3s ease; // Updated
  }
  a:hover img {
    filter: invert(70%) sepia(12%) saturate(2049%) hue-rotate(120deg)
      brightness(89%) contrast(87%);
    transform: rotateY(360deg);
  }
}
