// Experience.scss
.experience-container {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
  overflow: hidden;
  h3 {
    text-align: center;
    margin-bottom: 5rem;
  }
}

.timeline {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding: 0rem 2rem;

  &:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    height: 1px;
    background: #ccc;
    z-index: 1;
  }
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: linear-gradient(45deg, #39bda7, #8cffec);
  z-index: 2;
}

.details {
  text-align: center;
  margin-top: 1rem;

  p {
    min-height: 50px;
    line-height: 1.6;
  }

  .year {
    font-weight: bold;
  }

  .title {
    font-size: 0.9rem;
    color: #656565;
    margin-top: 0.5rem;
  }
}

.timeline-group {
  left: 0;
  display: flex;
  top: 0;
  width: 50%;
  padding: 0 !important;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .timeline-item {
    margin: 0px 10px;
  }
}

@media screen and (max-width: 767px) {
  .timeline {
    padding: 0;
  }
  // .timeline-item {
  //   margin: 0px;
  // }
  .experience-container {
    margin-bottom: 2rem;
  }
  .experience-title {
    font-size: 0.8rem;
  }
  .experience-container h3 {
    padding: 0rem 10px;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .timeline {
    display: flex;
    flex-direction: column;
  }
  .timeline-group {
    width: 100%;
    margin-bottom: 1rem;
    &:before {
      content: "";
      position: absolute;
      top: 127px;
      left: 0;
      right: 0;
      height: 1px;
      background: #ccc;
      z-index: 1;
    }
  }

  .details {
    p {
      line-height: inherit;
    }
  }
}
