.navbar {
  // height: 120px;
  z-index: 1;
  padding-top: 50px;
  background-color: #f5f9ff !important;
  padding-bottom: 0px;
}

.navbar-logo {
  height: 50px;
  transform: rotate(-8deg);
  padding: 0px;
}

.navbar-nav {
  width: 34%;
  display: flex;
  justify-content: center;
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  width: 23%;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #39bda7;
  span {
    font-weight: 700;
  }
}

// Hover effect for links
.navbar-nav .nav-link {
  color: #000;
  text-decoration: none;

  span {
    font-weight: 300;
    transition: all 0.5s;

    display: inline-block;

    &:nth-child(2) {
      transition-delay: 0.1s;
    }
    &:nth-child(3) {
      transition-delay: 0.2s;
    }
    &:nth-child(4) {
      transition-delay: 0.3s;
    }
    &:nth-child(5) {
      transition-delay: 0.4s;
    }
    &:nth-child(6) {
      transition-delay: 0.5s;
    }
    &:nth-child(7) {
      transition-delay: 0.6s;
    }
  }

  &:hover {
    span {
      font-weight: 700;
    }
    &:hover span:nth-child(1) {
      transition-delay: 0s;
    }
    &:hover span:nth-child(2) {
      transition-delay: 0.1s;
    }
    &:hover span:nth-child(3) {
      transition-delay: 0.2s;
    }
    &:hover span:nth-child(4) {
      transition-delay: 0.3s;
    }
    &:hover span:nth-child(5) {
      transition-delay: 0.4s;
    }
    &:hover span:nth-child(6) {
      transition-delay: 0.5s;
    }
    &:hover span:nth-child(7) {
      transition-delay: 0.6s;
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    padding: 2rem 1rem;
    padding-bottom: 2rem;
  }
  .navbar-brand {
    width: initial;
  }
  .navbar-nav {
    flex-direction: row;
    justify-content: flex-end;
    width: initial;
    .nav-link {
      margin-left: 1rem;
    }
  }
}
