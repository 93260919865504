.landing-page-wrapper {
  // height: calc(100vh - 120px);
  height: 100%;

  .photo-col {
    position: relative;

    .photo-wrapper {
      position: absolute;
      top: -120px;
      left: 0;
      right: 0;
      height: calc(100% + 120px);
      overflow: hidden;
      .photo-circle {
        position: absolute;
        z-index: 1;
        height: 57%;
        width: 64%;
        border-radius: 58% 61% 61% 58%/50% 44% 72% 60%;
        border: 1px solid #6c6c6c;
        display: none;
      }
      .photo-circle2 {
        height: 56%;
        width: 67%;
        border-radius: 70% 54% 63% 57%/52% 46% 77% 60%;
        border: 1px solid #6c6c6c;
        display: none;
      }
    }

    .main-photo {
      position: absolute;
      top: 0;
      z-index: 2;
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 0px 0px 300px 300px;
      }
    }
  }
}

.character {
  display: inline;
  transition: all 0.1s;
  cursor: default;
}

.hovered {
  color: #39bda7;
  transform: translateY(-5px);
  position: relative;
  text-shadow: 0 -1px 4px #fff, 0 -2px 10px rgb(0, 255, 200),
    0 -10px 20px #00ffbf, 0 -18px 40px rgb(0, 255, 213);
}

.hov {
  line-height: 1.8;
}

.adjacent {
  color: #7ff1de;
  transform: translateY(-2.5px);
}

.button {
  display: inline-block;
  width: 100%;
  padding: 10px 24px;
  border: 0.5px solid #e2e2e2;
  transition: all 0.1s ease-in;
  position: relative;
  overflow: hidden;
  font-weight: 600;
  font-size: 0.9rem;
  color: #fff;
  z-index: 1;
  border-radius: 32px;
  background: linear-gradient(45deg, #333, #6b6b6b);
}

.button:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #39bda7;
  border-radius: 50%;
  display: block;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button:hover {
  color: #ffffff;
  border: 0.5px solid #39bda7;
}

.button:hover:before {
  top: -35%;
  background-color: #39bda7;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button:hover:after {
  top: -45%;
  background-color: #39bda7;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.left-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  width: 70%;
  hr {
    margin: 1.5rem 0;
    width: 80%;
  }
  .landing-title {
    font-family: "DM Serif Display", serif;
    font-size: 3.5rem;
  }
  .subtitle {
    font-size: 1rem;
    font-style: italic;
    color: #b5b5b5;
  }
  p {
    font-size: 0.9rem;
    color: #656565;
    margin-bottom: 2rem;
  }
}

.right-text {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  p {
    font-size: 0.8rem;
    margin-top: 1rem;
  }

  hr {
    position: absolute;
    width: 20%;
    margin: 0;
  }

  > div {
    margin: 1rem 0rem;
  }
}

.right-text-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  overflow: hidden;
}

.vertical-numbers {
  font-size: 2rem;
  font-family: "PT Serif", serif;
  font-weight: bold;
  span {
    letter-spacing: -6px;
  }
}

.mouse-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100px;
}

.mouse {
  width: 25px;
  height: 50px;
  border: 1px solid #333;
  border-radius: 30px;
  position: absolute;
  bottom: 0px;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
    -webkit-animation: wheel 2s infinite;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 30px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 30px;
  }
}

@media screen and (max-width: 1399px) {
  .left-text {
    width: 90%;
  }
}

@media screen and (max-width: 1199px) {
  .left-text .landing-title {
    font-size: 2rem;
  }
  .vertical-numbers {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 991px) {
  .landing-page-wrapper .photo-col .photo-wrapper {
    position: initial;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
  .landing-page-wrapper .photo-col .main-photo {
    height: initial;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    img {
      border-radius: 67% 33% 61% 39% / 47% 30% 70% 53%;
      width: 70%;
    }
  }

  .landing-page-wrapper .photo-col .photo-wrapper .photo-circle {
    z-index: 3;
    display: block;
  }

  .right-text-container {
    min-height: initial;
  }

  .left-text {
    min-height: initial;
    padding: 2rem 0rem;
    width: 90%;
  }

  .right-text {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    hr {
      width: 10%;
    }
  }
  .mouse-container {
    height: 6rem;
  }
}

@media screen and (max-width: 767px) {
  .vertical-numbers {
    font-size: 1.4rem;
    span {
      letter-spacing: -3px;
    }
  }

  .photo-col {
    order: -1;
  }
  .right-col {
    order: 2;
  }
  .right-text {
    flex-wrap: wrap;
    > div {
      margin: 0;
      width: 50%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    hr {
      position: relative;
      margin: 0 auto;
      width: 30%;
    }
    p {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }
  .left-text {
    width: 100%;
    text-align: center;
    hr {
      margin: 1.5rem auto;
      width: 50%;
    }
  }
  .landing-page-wrapper .photo-col .main-photo img {
    width: 45%;
  }
  .landing-page-wrapper .photo-col .photo-wrapper .photo-circle {
    height: 103%;
    width: 42%;
    border-radius: 58% 61% 61% 58%/50% 44% 72% 60%;
  }
  .landing-page-wrapper .photo-col .photo-wrapper .photo-circle2 {
    height: 107%;
    width: 41%;
    border-radius: 72% 61% 74% 57%/60% 53% 74% 64%;
  }
  .left-text .landing-title {
    font-size: 2.5rem;
  }
}
