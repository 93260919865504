.project-logo {
  height: 40px;
  width: auto;
}

.project-card {
  border-radius: 16px;
  transition: all 0.3s ease, border 0.3s ease;
  border: 4px solid #f5f9ff;
  filter: grayscale(100%);
  position: relative;
  padding: 10px;
}

.project-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: stretch 0.2s forwards;
  border: 4px solid #fff;
  filter: grayscale(0%);
  z-index: 9999;
}

.project-card:not(:hover) {
  animation: reverse-stretch 0.2s forwards;
}

@keyframes stretch {
  0% {
    transform: scale(1);
    z-index: 9999;
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes reverse-stretch {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.row .project-links {
  text-decoration: none;
  cursor: pointer;
}

.project-title {
  text-decoration: none;
  font-size: 1.2rem;
}

.latest-projects {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
  .title {
    text-align: center;
  }
  code {
    margin-bottom: 2rem;
    text-align: center;
  }
}

.tech-used {
  font-weight: 600;
  border: 0.5px solid white;
  padding: 2px 8px;
  border-radius: 20px;
  font-size: 0.8rem;
}

.project-description {
  line-height: 1.4;
  font-size: 0.9rem;
}

//. PROJECTS

.project1 {
  background-color: #073130;
  .project-logo {
    height: 60px;
    width: auto;
  }
  .project-title {
    color: white;
  }
  .project-description {
    color: white;
  }
  .tech-used {
    color: white;
  }
}

.project2 {
  background-color: #f2bca1;
  .project-logo {
    height: 60px;
    width: auto;
  }
  .project-title {
    color: #333;
  }
  .project-description {
    color: #333;
  }
  .tech-used {
    color: #333;
    border: 0.5px solid #333;
  }
}
.project3 {
  background-color: #fff;
  .project-logo {
    width: 100px;
    height: auto;
  }
  .project-title {
    color: #333;
  }
  .project-description {
    color: #333;
  }
  .tech-used {
    color: #333;
    border: 0.5px solid #333;
  }
}
.project4 {
  background-color: #204d62;
  .project-logo {
    width: 150px;
    height: auto;
  }
  .project-title {
    color: white;
  }
  .project-description {
    color: white;
  }
  .tech-used {
    color: white;
  }
}
.project5 {
  background-color: #fff;
  .project-logo {
    width: 200px;
    height: auto;
  }
  .project-description {
    color: #333;
  }
  .tech-used {
    color: #333;
    border: 0.5px solid #333;
  }
}
.project6 {
  background-color: #343434;
  .project-logo {
    height: 60px;
    width: auto;
  }
  .project-title {
    color: white;
  }
  .project-description {
    color: white;
  }
  .tech-used {
    color: white;
  }
}

@media screen and (max-width: 991px) {
  code {
    font-size: 0.82rem;
  }
}

@media screen and (max-width: 767px) {
  .latest-projects {
    padding-top: 2rem;
    hr {
      margin: 0.5rem 0;
    }
  }
}
