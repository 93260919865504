html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: #f5f9ff;
  scroll-behavior: smooth;
}

.ml-auto {
  margin-left: auto;
}

.App {
  overflow: hidden;
}
