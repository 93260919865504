.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9 !important;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 50%;
  text-align: center;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.contact {
  padding-top: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.contact {
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
    margin: 2rem auto;
    span {
      color: #39bda7;
      font-size: 1rem;
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
}

.wave-group {
  position: relative;
  margin-bottom: 2.3rem;
}

.wave-group .input {
  font-size: 0.8rem;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #515151;
  background: transparent;
}

.wave-group .input:focus {
  outline: none;
}

.wave-group .label {
  color: #999;
  font-size: 0.8rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  display: flex;
}

.wave-group .input:focus ~ .label,
.wave-group .input:valid ~ .label {
  transform: translateY(-30px);
  font-size: 0.8rem;
  color: #39bda7;
}

.wave-group .bar {
  position: relative;
  display: block;
  width: 100%;
}

.wave-group .bar:before,
.wave-group .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #39bda7;
  transition: 0.2s ease all;
}

.wave-group .bar:before {
  left: 50%;
}

.wave-group .bar:after {
  right: 50%;
}

.wave-group .input:focus ~ .bar:before,
.wave-group .input:focus ~ .bar:after {
  width: 50%;
}

.contact-button {
  width: 50% !important;
  margin: 0 auto;
}

.br-only-mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .modal-content {
    width: 80%;
  }
  form {
    width: 80%;
  }
  .contact-button {
    width: 80% !important;
  }
}

@media screen and (max-width: 767px) {
  .contact {
    padding-top: 2rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 485px) {
  .br-only-mobile {
    display: block;
  }
}
